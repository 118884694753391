@font-face {
  font-family: "GothamBook";
  src: url("../fonts/GothamBook.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "GothamMedium";
  src: url("../fonts/GothamMedium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "GothamBold";
  src: url("../fonts/GothamBold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("../fonts/RubikBolder.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

body {
  font-size: 1rem;
  @apply text-eerieblack font-gotham-regular bg-ghostwhitelight dark:bg-darkgunmetal dark:text-brightgray bg-white;
}

/* Utility Class */
.btn-primary,
button[type="button"].btn-orange,
button[type="submit"].btn-orange {
  @apply text-white px-4 py-2 rounded w-full font-gotham-medium;
  background-color: #7f2cea;
  font-size: 0.875rem;
}
@screen maxMd {
  .btn-primary,
  button[type="button"].btn-orange,
  button[type="submit"].btn-orange {
    @apply px-3 py-2 text-sm;
  }
}

.btn-primary:hover,
button[type="button"].btn-orange:hover,
button[type="submit"].btn-orange:hover {
  background-color: #7f2ceaab;
  transition: background-color 0.4s;
}
.font-xs {
  font-size: 0.9rem;
  @apply leading-6;
}

/* Form */
.form-group {
  @apply flex flex-col mb-6 relative;
}
.form-label {
  @apply mb-2 font-gotham-medium;
  font-size: 0.9rem;
}
.form-control {
  padding: 0.5rem 0.625rem;
  @apply rounded-md border border-columbiablue w-full text-black outline-none;
}
.dark .form-control {
  @apply bg-darkgunmetal border-lavenderblue text-white;
}
.dark .form-control:disabled {
  @apply bg-darkgunmetal border-transparent;
}
.form-control::placeholder {
  @apply text-quicksilver;
}
.form-control:disabled {
  @apply bg-ghostwhite;
}
.form-control-addon {
  @apply relative;
}
.form-control-addon .form-control {
  @apply pl-12 pr-8;
}
.form-control-addon img {
  @apply absolute top-0 bottom-0 my-auto;
  max-height: 1.625rem;
  left: 0.625rem;
  z-index: 9;
}
.form-control-addon img:last-child {
  @apply left-auto;
  right: 0.625rem;
}
.orange-link {
  @apply underline font-gotham-medium;
  color: #7f2cea;
  font-size: 0.9rem;
}
.form-select {
  @apply bg-transparent text-black border border-lavenderblue rounded-md w-full outline-none md:px-2.5 md:py-2 py-2.5 px-2 appearance-none relative cursor-pointer;
}
.form-select-arrow {
  @apply inline-block relative cursor-pointer;
}
.form-select-arrow:before {
  content: "";
  right: 1.375rem;
  background: url("../images/downarrow.svg") no-repeat right;
  width: 1.875rem;
  height: 1.875rem;
  @apply absolute z-0 top-1.5;
}
.is-invalid {
  border-radius: 0.375rem;
  @apply border border-red-600;
}
.form-error {
  @apply text-red-600 text-sm absolute;
  bottom: -1.313rem;
}
.form-error-server {
  @apply text-red-600 text-sm;
}
.brand-logo {
  @apply flex items-center mx-auto;
}

.dark .brand-logo rect {
  fill: white;
}

.brand-logo h1 {
  font-size: 2.8rem;
  line-height: 2.438rem;
  @apply ml-2 font-rubik;
}

.check {
  width: 1.125rem;
  height: 1.125rem;
  @apply cursor-pointer;
}
@screen maxSm {
  .check {
    @apply w-3.5 h-3.5;
  }
}

@screen minMaxSm {
  .form-control {
    @apply py-2 px-2.5;
  }
  .form-group {
    @apply mb-4;
  }
  button[type="button"].btn-orange,
  button[type="submit"].btn-orange {
    @apply py-2;
  }
}
@screen maxSm {
  .form-group {
    @apply mb-6 text-sm;
  }
  .form-label {
    @apply mb-1;
  }
  .form-control {
    @apply px-2 py-2.5;
  }
}

@screen minMaxMd {
  .form-group {
    @apply mb-6;
  }
}

@screen maxLg {
  .brand-logo svg {
    @apply w-6 h-6;
  }
  .brand-logo h1 {
    font-size: 1.75rem;
  }
}
.dark .card {
  @apply bg-black1;
}
.card {
  @apply bg-white dark:bg-black1 shadow-shadowCustom flex flex-col justify-center items-center;
  /* max-width: 42.188rem; */
  padding: 3.625rem;
  border-radius: 0.5rem;
}
@screen maxMd {
  .card {
    @apply px-10;
  }
}
@media screen and (min-width: 300px) and (max-width: 400px) {
  .card {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }
}

section.sidebar {
  background-color: #8900cc;
}
.sidebar ul::-webkit-scrollbar {
  display: none;
}
.sidebar ul {
  scrollbar-width: none;
}

.card-header {
  @apply font-gotham-bold w-full text-center;
  font-size: 1.625rem;
}
@screen maxSm {
  .card-header {
    @apply text-center;
    font-size: 1.375rem;
  }
}

.svg-content {
  @apply relative;
}

.svg-content:before {
  content: "";
  @apply left-0 bottom-0 absolute -z-10;
  background: url("../images/signup-bg.png") no-repeat;
  width: 30rem;
  height: 18rem;
  background-size: contain;
}
.svg-content:after {
  content: "";
  background: url("../images/coin.svg") no-repeat;
  width: 15.063rem;
  height: 13.625rem;
  @apply right-0 bottom-0 absolute -z-10;
}
@screen maxMd {
  .svg-content:after,
  .svg-content:before {
    @apply hidden;
  }
  .svg-content:before {
    left: inherit;
    width: 18.75rem;
    height: 14.375rem;
    @apply right-0;
  }
}

@screen minMaxMd {
  .svg-content:before {
    @apply bottom-0;
    height: 15.625rem;
  }
  .svg-content:after {
    @apply bottom-0;
    width: 18.75rem;
    height: 12.5rem;
  }
}
@media screen and (min-width: 300px) and (max-width: 480px) {
  .svg-content:before {
    @apply hidden;
  }
  .header-right .show-mobile {
    display: block !important;
  }
  .header-right .show-web {
    display: none !important;
  }
}

/* Switch */
.toggle-checkbox {
  @apply hidden;
}
.switch-active.toggle-label {
  @apply bg-safetyorange;
}
.switch-active.toggle-label:before {
  content: "";
  @apply bg-white;
}
.toggle-label {
  width: 3.5rem;
  height: 1.6rem;
  @apply bg-brightgray cursor-pointer flex relative;
  border-radius: 6.25rem;
}
.toggle-label:before {
  content: "";
  width: 1.01rem;
  height: 1.01rem;
  top: 0.3rem;
  transform: translateX(2rem);
  transition: transoform 0.3s ease-in-out;
  background: url("../images/sun.svg");
  filter: grayscale(0.9);
  @apply absolute bg-contain;
}
.switch-active.toggle-label:before {
  @apply opacity-50;
  content: "";
  box-shadow: -0.25rem 0.375rem 0 0.125rem #f7c5a2;
  width: 0.9rem;
  height: 0.9rem;
  top: -1px;
  transform: translateX(1rem);
  filter: none;
  @apply border-transparent rounded-full absolute bg-none bg-transparent;
}
.toggle-label::after {
  content: "";
  width: 1.02rem;
  height: 1.02rem;
  top: 0.25rem;
  transform: translateX(0.625rem);
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.17);
  transition: transform 0.25s ease-in-out;
  @apply border-2 border-argent absolute bg-white rounded-full;
}

.switch-active.toggle-label::after {
  transform: translateX(2rem);
  transition: transform 0.3s;
  @apply border-argentlight;
}
.heading-lg {
  @apply dark:text-brightgray font-gotham-medium text-lg;
}
.heading-md {
  @apply text-lg font-gotham-bold;
}

main,
header {
  @apply flex;
}
main {
  @apply items-start;
}

/* Header */

header {
  @apply pt-9 pb-8 items-center mb-8;
  height: 75px;
}

header::after,
header::before {
  @apply absolute;
  content: "";
  width: calc(100% - 14.5rem);
  left: 14.5rem;
}

header::after {
  @apply bg-white dark:bg-richblack top-0;
  height: 75px;
  z-index: -1;
}

header::before {
  @apply border-b border-azureishwhite dark:border-raisinblack;
  top: 77px;
  border-left-width: 1px;
}

@screen maxLg {
  header::after,
  header::before {
    @apply w-full left-0;
  }
}

@screen lg {
  header .brand-logo {
    @apply hidden;
  }
}
.header-right {
  @apply md:ml-auto flex items-center;
}

/* Responsive Header */
@screen maxLg {
  header {
    @apply flex bg-white w-full mx-0 py-5 dark:bg-black1;
  }
  header .heading-lg {
    @apply hidden;
  }
  header .brand-logo {
    @apply ml-14;
  }
  .header-right {
    @apply ml-auto mr-4;
  }
  header .switch {
    @apply hidden;
  }
}

@media (max-width: 374px) {
  .menu-right {
    max-width: 7.5rem;
  }
}

@media (min-width: 768px) and (max-width: 799px) {
  .menu-right button {
    font-size: 0.9rem !important;
    @apply px-2 !important;
  }
}

.hamburger {
  @apply z-10 rounded-lg flex justify-center items-center flex-col absolute h-6 w-6;
  top: 1.75rem;
  left: 1rem;
  cursor: pointer;
}
.line {
  @apply rounded-lg bg-catalinablue;
  width: 21px;
  height: 3px;
  margin: 2.5px auto;
}

.overlay {
  @apply fixed w-full top-0 bottom-0 transition-[all];
}
.overlay::before {
  content: "";
  @apply z-10 absolute inset-0;
  background: rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}
.dark .overlay::before {
  background: rgb(249 249 249 / 50%);
}

/* Sidebar */
.sidebar,
.right-sidebar {
  @apply bg-white dark:bg-black1 py-3 fixed border-azureishwhite dark:border-raisinblack;
}
.sidebar {
  @apply border-r;
  min-width: 14.5rem;
}
@screen maxMd {
  .sidebar {
    @apply absolute;
  }
}
.sidebar .brand-logo {
  @apply mt-2 ml-8;
}
.sidebar .brand-logo svg {
  max-width: 2.125rem;
}
.sidebar .brand-logo h1 {
  font-size: 2rem;
  line-height: 1.75rem;
}
.sidebar ul {
  @apply px-5 overflow-y-auto;
}
@screen maxLg {
  .sidebar ul {
    @apply mt-9;
  }
}
@screen lg {
  .sidebar ul {
    @apply mt-10;
  }
}
@screen maxLg {
  .sidebar ul {
    @apply px-4;
  }
  .sidebar .brand-logo {
    @apply hidden;
  }
  .sidebar-bottom {
    @apply fixed flex items-center left-auto right-auto mx-6  py-2;
    width: 215px;
  }
}
@screen lg {
  .sidebar-bottom {
    @apply hidden;
  }
}
.sidebar ul li {
  @apply rounded-md;
  margin-bottom: 0.313rem;
}
.sidebar ul li svg {
  @apply mr-5;
  transition: 0.3s;
  fill: #fff;
}
.dark .sidebar ul li svg path {
  fill: #a6a6a6;
}
.sidebar li a {
  padding: 0.875rem;
  @apply font-gotham-medium flex rounded-md text-sm items-center;
  color: #fff;
}

.sidebar li .active,
.sidebar li:hover,
.sidebar li:hover a {
  @apply bg-lotion dark:bg-darkgunmetal;
  color: #7f2cea;
}

.sidebar li:hover svg path,
.sidebar li .active svg path,
.dark .sidebar ul li:hover svg path,
.dark .sidebar li.active svg path,
.dark .sidebar li .active svg path {
  fill: #7f2cea;
}

@screen maxLg {
  .sidebar {
    @apply absolute;
    z-index: 11;
    left: -500px;
    transition: left 0.3s;
  }
  .sidebar-open {
    @apply left-0;
  }
  .close .line:first-child {
    transform: rotateZ(-45deg);
    margin-top: 3px;
  }
  .close .line:nth-child(2) {
    transform: rotateZ(40deg);
    margin-top: -5px;
  }
  .close .line:last-child {
    @apply hidden;
  }
  .dark .close .line,
  .dark .line {
    @apply bg-white;
  }

  .close.hamburger {
    margin-left: 180px;
    z-index: 12;
    top: 0.75rem;
    transition: margin-left 0.3s;
  }
  .sidebar li a {
    padding: 0.75rem 1rem;
  }
}

.admin-profile {
  @apply fixed bottom-0 left-auto right-auto dark:bg-black1 border-r border-azureishwhite dark:border-raisinblack;
  min-width: 14.5rem;
  background-color: #8900cc;
}
.admin-profile-bottom {
  @apply my-5 mx-6;
}
.admin-profile-content {
  @apply flex items-center justify-center border-t border-b border-azureishwhite py-2;
}
.dark .admin-profile-content {
  @apply border-raisinblack;
}
.dark .admin-profile svg path {
  fill: #a6a6a6;
}
.admin-profile .heading-md {
  @apply text-sm;
  width: 6.625rem;
}
.admin-profile img {
  @apply mr-4 rounded-md;
  height: 2rem;
  width: 2rem;
}

@screen maxMd {
  .admin-profile-content img {
    max-width: 2.5rem;
  }
  .admin-profile-content svg {
    width: 1.375rem;
  }
  .admin-profile-content button {
    @apply ml-6;
  }
}
.sidebar li:hover svg path {
  fill: #7f2cea;
}
.loader {
  border: 0.188rem solid #fff;
  border-top: 0.375rem solid #7f2cea;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 0.25s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Dropdown Language */
.lang-dropdown .css-1gvgxyu-option,
.lang-dropdown .css-nk38os-option,
.lang-dropdown .css-1n7v3ny-option,
.lang-dropdown .css-1n7v3ny-option:active {
  @apply cursor-pointer bg-lightgray;
}
.lang-dropdown .css-c3yzud-option,
.lang-dropdown .css-9gakcf-option,
.lang-dropdown .css-nk38os-option:hover,
.lang-dropdown .css-nk38os-option:active,
.lang-dropdown .css-9gakcf-option:active {
  @apply text-black bg-lightgray cursor-pointer;
}

.lang-dropdown .css-u2835m-option:focus {
  @apply bg-transparent !important;
}

.lang-dropdown .css-b62m3t-container {
  @apply flex mr-3 cursor-pointer;
}
.lang-dropdown .css-5bvrfe-control {
  @apply cursor-pointer;
}
.lang-dropdown #react-select-3-listbox {
  width: 10rem;
}
@screen maxLg {
  .header-right .lang-dropdown {
    @apply hidden;
  }
}

.down-arrow {
  @apply relative;
}
.down-arrow::after {
  content: "";
  border-left: 0.25rem solid transparent;
  border-right: 0.25rem solid transparent;
  @apply border-t border-t-eerieblack dark:border-t-lightquicksilver w-0 h-0 absolute;
  border-top-width: 0.5rem;
  right: 0.25rem;
  top: 0.313rem;
}
/* .header-right .css-319lph-ValueContainer,
.sidebar .css-319lph-ValueContainer {
  @apply p-0;
} */
.css-319lph-ValueContainer {
  padding: 0px !important;
}
.header-right .css-1okebmr-indicatorSeparator,
.sidebar .css-1okebmr-indicatorSeparator {
  @apply w-0;
}
.header-right .css-26l3qy-menu,
.sidebar .css-26l3qy-menu {
  min-width: 9.375rem;
  top: 2.25rem !important;
}
.header-right .show-mobile {
  display: none;
}

.header-right .css-26l3qy-menu,
.sidebar .css-26l3qy-menu {
  @apply bg-ghostwhitelight2 dark:bg-darkgunmetal border border-lightquicksilver dark:border-outerspace absolute left-0 right-0 py-0 font-gotham-medium;
  border-radius: 0.625rem;
  top: 2.438rem;
  width: 8.438rem;
  z-index: 1;
  box-shadow: 0.438rem 0.75rem 1.25rem rgba(0, 0, 0, 0.17);
}

.main-content {
  @apply w-full;
}
@media screen and (min-width: 1326px) {
  .main-content {
    /* width: calc(100% - 40.626rem); */
    margin-left: 17.313rem;
    margin-right: 2.813rem;
  }
  header::after,
  header::before {
    width: calc(100% - 14.5rem);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1325px) {
  .main-content {
    margin-left: 18.938rem;
    margin-right: 4.438rem;
    width: calc(100% - 23.376rem);
  }
}

.text-success {
  @apply text-cadmiumgreen text-center text-sm;
}

/* toggle button design */
.switch-default {
  @apply relative inline-block;
  width: 3.75rem;
  height: 2.125rem;
}
@screen maxSm {
  .switch-default {
    width: 3.25rem;
    height: 1.875rem;
  }
  .switch-default .slider:before {
    height: 1.125rem !important;
    width: 1.125rem !important;
    left: 0.25rem !important;
    bottom: 0.375rem !important;
  }
}

.switch-default input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-default .slider {
  @apply absolute cursor-pointer bg-silversand;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  width: 3.5rem;
  height: 1.6rem;
}
.dark .switch-default .slider {
  @apply bg-darkgunmetal;
}
.switch-default .slider:before {
  @apply bg-white absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 0.5rem;
  bottom: 0.25rem;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.switch-on-off.switch-default input:checked + .slider,
.switch-default input:checked + .slider {
  @apply bg-safetyorange !important;
}
.switch-on-off.switch-default input + .slider {
  @apply bg-cinnabar !important;
}

.switch-default input:checked + .slider:before {
  -webkit-transform: translateX(1.4rem);
  -ms-transform: translateX(1.4rem);
  transform: translateX(1.4rem);
}

/* Rounded sliders */
.switch-default .slider.round {
  border-radius: 2.125rem;
}

.switch-default .slider.round:before {
  @apply rounded-full;
}
button[type="button"].btn-danger,
button[type="button"].btn-edit,
button[type="button"].btn-back,
button[type="button"].btn-brightgray {
  @apply px-4 py-2 flex items-center w-auto justify-center;
  font-size: 0.875rem;
  border-radius: 0.375rem;
}
button[type="button"].btn-back,
button[type="button"].btn-edit {
  @apply border border-cornflowerblue text-cornflowerblue;
  height: 2.438rem;
}

button[type="button"].btn-back {
  width: 100%;
}

button[type="button"].btn-back:hover {
  opacity: 0.7;
  transition: ease;
}

button[type="button"].btn-danger {
  @apply bg-cinnabar text-white;
  height: 2.438rem;
}
button[type="button"].btn-danger .loader {
  border-top: 0.375rem solid #ea4335;
}
button[type="button"].btn-brightgray,
button[type="button"].btn-brightgray:hover {
  @apply bg-brightgray text-eerieblack;
}
.dark button[type="button"].btn-brightgray {
  @apply bg-vampireblack text-lightquicksilver;
}
/* modal css */

.modal {
  @apply bg-white flex flex-col items-center justify-center fixed z-10;
  border-radius: 1.25rem;
  box-shadow: 0px 4px 193px 28px rgba(0, 0, 0, 0.12);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: modalTranstition 300ms;
}
.settings .modal {
  width: 41.563rem;
}
@screen maxMd {
  .settings .modal {
    width: 90%;
  }
}
@keyframes modalTranstition {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
.dark .modal {
  @apply bg-vampireblack;
}

@screen maxSm {
  .delete__account img {
    height: 2rem;
    width: 2rem;
  }
}
.modal-lg {
  width: 75%;
}
@screen maxMd {
  .modal-lg {
    width: 90%;
  }
}

@media (max-width: 1279px) {
  .modal-lg {
    @apply overflow-y-auto justify-start;
    height: 600px;
  }
}
.modal_close {
  @apply absolute z-10;
  top: 1.75rem;
  right: 3.125rem;
}
@screen maxSm {
  .modal_close {
    right: 1.563rem;
  }
}
.delete__account {
  @apply flex flex-col justify-center items-center;
}
.delete__account h3 {
  @apply mt-6 text-xl font-gotham-bold;
}
.delete__account p {
  @apply text-center mt-1.5 text-sm;
  max-width: 22.25rem;
}
.modal button {
  @apply font-gotham-bold;
}
.dark .modal button img {
  filter: invert(1);
}

.modal .modal__button {
  @apply flex mt-6;
}
.modal .modal__button button:first-child {
  margin-right: 1.25rem;
}
.modal .modal__button button:last-child {
  margin-left: 1.25rem;
}

.modal-header {
  @apply relative w-full flex items-center p-8;
}
.modal-footer {
  @apply p-8 w-full flex justify-end;
}
.modal-footer button {
  @apply w-auto !important;
}
.modal-footer button:first-child {
  @apply mr-6;
}
.modal-body {
  @apply pb-8 px-8 w-full;
}
.dark .modal-header img {
  filter: invert(1) brightness(200);
}
.modal .form-label {
  @apply text-base;
}

/* Tag Design */
.tag-design {
  @apply py-1 px-4 mt-1 rounded-md text-xs text-safetyorange font-gotham-medium;
}

@media (max-width: 401px) {
  .tag-design {
    @apply mb-4;
  }
}

.warning {
  max-width: 75rem;
  @apply p-5 rounded-xl relative mx-auto mt-10 bg-aliceblue;
}
.warning-btn {
  box-shadow: 0px 0px 24px rgb(0 0 0 / 10%);
  top: -0.75rem;
  right: -0.625rem;
  @apply bg-white absolute w-6 h-6 rounded-full;
}

.warning-btn img {
  @apply mx-auto block object-contain w-2.5 h-2.5;
}
.warning p {
  max-width: 50rem;
}
.Toastify__toast {
  @apply font-gotham-regular !important;
}
.Toastify__toast-body {
  @apply items-start !important;
}
.Toastify__toast-icon {
  @apply mt-1;
}

/* right sideabr design */

.right-sidebar {
  @apply right-0 border-l px-5 hidden overflow-y-auto;
  min-width: 20.5rem;
}

.right-sidebar__content {
  @apply mt-4;
}

.right-sidebar .item-card {
  @apply shadow-shadowCustom dark:shadow-darkShadowCustom bg-white dark:bg-darkgunmetal;
  max-width: 17.888rem;
  border-radius: 0.5rem;
}

.right-sidebar .card-body {
  @apply p-6 pt-3;
}

.right-sidebar .network-fees .card-body {
  @apply p-3;
}

.right-sidebar .network-fees__list li {
  @apply flex justify-between;
}

.right-sidebar .network-fees__list li h2 {
  @apply text-base font-gotham-medium;
}

.right-sidebar .network-fees__list li p {
  @apply text-xs text-quicksilver;
}

.right-sidebar .network-fees .token {
  @apply mt-6;
}

/* .right-sidebar .network-fees .token__body{
  @apply border-b border-azureishwhite
} */

.right-sidebar .network-fees .token__info {
  @apply flex justify-between mt-2;
}

.right-sidebar .token__info p {
  @apply text-xs ml-1 text-quicksilver !important;
}

.right-sidebar .network-fees .token__info-heading {
  @apply font-gotham-bold;
}

.right-sidebar .network-fees .token__info h6 {
  @apply ml-1 text-seagreen text-xs;
}

.right-sidebar .network-fees .token__info span {
  @apply text-quicksilver text-sm;
}

/* .right-sidebar .network-fees .token__info span{
  @apply text-sm
} */

.right-sidebar .network-fees .token h2 {
  @apply text-sm font-gotham-medium pb-2 border-b border-azureishwhite;
}

.right-sidebar .card-heading {
  @apply p-4 mb-0 border-b border-azureishwhite dark:border-brightgray;
}

.right-sidebar .card-body h3 {
  @apply text-quicksilver text-xs;
  letter-spacing: 0.2px;
}

.right-sidebar .card-body button {
  @apply w-auto;
}

.right-sidebar .artical {
  @apply flex items-center;
}

.right-sidebar .card-body li:not(:last-child) {
  @apply mb-2;
}

.right-sidebar .artical .artical__time {
  @apply text-quicksilver text-xs;
}

.right-sidebar .artical .artical__title {
  @apply font-gotham-medium text-sm;
}

.right-sidebar .artical .artical__icon {
  @apply mr-3;
}

.right-sidebar .token .token__heading {
  @apply font-gotham-medium text-center mb-3;
}

.right-sidebar .token p {
  @apply text-sm text-center;
}

@media screen and (min-width: 1326px) {
  .right-sidebar {
    @apply block;
  }
}

.dark .text-gray-500,
.dark .text-black {
  color: #fff;
}

.dark .bg-gray {
  background-color: rgba(128, 128, 128, 0.585);
}
.dark .bg-gray:hover {
  background-color: gray;
}
.dark .pageNumber.active {
  background-color: gray;
}
.light .pageNumber.active {
  background-color: rgb(122 111 111 / 20%);
}
.dark .dark-mode-bg-color {
  background-color: rgb(34 36 42 / var(--tw-bg-opacity)) !important;
}
.dark .noticeBox {
  background-color: #000;
}
.dark .filter-bar__filter {
  background-color: black;
}
.dark .slider {
  background-color: #000 !important;
}
@media screen and (max-width: 2400px) {
  .sappayTable {
    overflow-x: auto;
    display: block;
    max-width: 800px;
  }
  /* .sappayTable th {
    width: 50%;
  } */
}

/* signup page */
.input-select-disabled {
  background-color: #f7f9fb;
  pointer-events: none;
}

input[type="text"] {
  background-color: transparent;
}

.signup-content {
  @apply w-full flex justify-center items-center flex-col px-3;
  padding: 2rem 0;
}

.form-control.select {
  padding: 2px;
}

.form-card,
.signup-card,
.boarding-card {
  @apply relative;
  max-width: 31.25rem;
  @apply bg-white rounded-xl w-full shadow-default;
  padding: 2.5rem;
}
.signBg {
  background-image: url(../images/signBg.png);
  background-size: cover;
}
.signup-card {
  max-width: 40.25rem;
}
.emptyTable svg {
  margin: 0 auto;
  margin-bottom: 2rem;
}
.bussiness-select.form-control {
  padding: 4px 2px 2px 8px;
}
select {
  background-color: transparent;
}
/* .dark .select {
  background-color: black !important;
} */
select.form-control {
  padding: 12px 0.625rem;
}
.radioFun {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.radioFun label {
  width: 47.5%;
  padding: 10px 0.625rem;
}
.radioFun span {
  font-weight: bold;
  padding-left: 4px;
  padding-top: 8px;
  display: inline-block;
}
.notificationCount {
  position: absolute;
  top: -4px;
  right: -3px;
  background-color: red;
  text-align: center;
  font-size: 10px;
  height: 16px;
  line-height: 1.8;
  width: 16px;
  border-radius: 50px;
  color: #fff;
}
.autoSearch .wrapper {
  border-radius: 8px !important;
  z-index: 9999 !important;
  box-shadow: none !important;
  border-color: rgb(203 213 225 / 1);
}

.autoSearch .wrapper:focus-within {
  box-shadow: none !important;
}
.autoSearch .wrapper ul {
  padding: 8px 0 0px 0 !important;
}
.autoSearch .wrapper li {
  padding: 10px 0 !important;
  cursor: pointer;
}

.fDbOPw {
  min-height: 42px !important;
}

.autoSearch .sc-hLBbgP svg {
  opacity: 0;
}

.autoSearch img {
  z-index: 99999;
}

.radioFun p {
  font-size: 12px;
  padding: 6px 8px 0 26px;
}
.radioFun ul {
  padding-top: 6px;
}
.radioFun li {
  font-size: 12px;
  margin-left: 42px;
  list-style: circle;
}
.radioFun input[type="radio"] {
  height: 16px;
  width: 16px;
  position: relative;
  top: 3px;
  margin-right: 6px;
}
.refundItems input {
  margin-left: 1rem;
  width: 16px;
}
.refundItems label {
  display: block;
}
.copyIcon svg {
  padding: 15px;
  border: 1px solid #000;
  border-radius: 50px;
  height: 3.5rem;
  width: 3.5rem;
}
.pageNumber {
  width: 40px;
  justify-content: center;
}

.radioFun .active {
  background: #8564ea;
  color: #fff;
}
.addInvoice .radioFun label {
  padding: 10px 16px 16px;
}
.justify-between {
  position: relative;
}
.keyLogs p {
  font-size: 12px;
}
.keyLogs {
  background-color: whitesmoke;
  padding: 1.5rem;
  border-radius: 6px;
  margin-top: 1.5rem;
}
.noticeBox {
  background-color: whitesmoke;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}
.noticeBox p {
  margin: 1rem 0;
  font-size: 14px;
}
.noticeBox li {
  list-style: initial;
  margin-left: 1rem;
  font-size: 14px;
}
span.keyDelete {
  position: absolute;
  right: -1.5rem;
  top: 8px;
  cursor: pointer;
}

.form-control.disabled {
  opacity: 0.8;
}

.terms-and-condition {
  height: 50vh;
  overflow-y: auto;
  padding: 0.5rem;
  padding-left: 1rem;
}

.terms-and-condition::-webkit-scrollbar {
  width: 1em;
}
.terms-and-condition::-webkit-scrollbar-track {
  margin-block: 0.5em;
}
.terms-and-condition::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  border-radius: 100vw;
  border-style: solid;
  border-color: white;
}
.terms-and-condition::-webkit-scrollbar-thumb:hover {
  background: #eff0f6;
}

.form-control input:focus-visible {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.dark .step {
  color: #fff;
}

.step {
  color: #7f2cea;
}

.step-circle {
  text-align: center;
  border-color: #7f2cea;
}

.dark .step .step-circle {
  border-color: #fff;
}

.dark .step-circle > svg {
  fill: #fff;
}

.dark .step.active .step-circle {
  border-color: #fff;
  background-color: #7f2cea;
}

.step.active .step-circle {
  background-color: #7f2cea;
}

.dark .active .step-circle > svg {
  fill: #fff;
}

.step-circle > svg {
  display: unset;
  margin-top: -5px;
  fill: #7f2cea;
}
.active .step-circle > svg {
  fill: #fff;
}

span.form-error {
  display: block;
}

.step-line.active {
  border-color: #7f2cea;
}

.step-form-success {
  transform: scale(1.75);
  margin: 0.5rem auto;
  fill: #7f2cea;
}

.boarding-card {
  max-width: 50rem;
}

input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"].no-arrows {
  -moz-appearance: textfield;
}

.table-action {
  max-height: 2rem;
}

.tab-content {
  width: 70%;
}
.searchBar button {
  border: 1px solid #cad4e2;
}

button.secondary {
  border: 1px solid #cad4e2;
  border-radius: 10px;
  padding: 0.5rem;
  font-weight: bold;
}

.addInvoice .flex {
  position: relative;
}
.addInvoice span.keyDelete {
  top: 2rem;
}

@media screen and (max-width: 768px) {
  .tab-content {
    width: 100%;
  }
}

.section-holder {
  margin-bottom: 1rem;
}

.section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.section-head > h1 {
  font-weight: bold;
  font-size: x-large;
}

.edit-btn {
  border: 1px solid #e5e7eb;
  border-radius: 50px;
  padding: 0.4rem 1rem;
}

.edit-btn:hover {
  color: #7f2cea;
  border-color: #7f2cea;
}

.paginate-btn svg,
.table-action svg {
  fill: #c4c4c4;
}

.paginate-btn:hover svg,
.table-action:hover svg {
  fill: #7f2cea;
}

.table-row {
  cursor: pointer;
}
.light .table-row:hover {
  background-color: #f9fafb;
}

.btn-orange.add-btn {
  width: max-content !important;
}

.share-model {
  max-width: 50%;
  min-width: 20rem;
  text-align: center;
}

.share-model .modal-body {
  margin-top: -4rem;
}

.share-model h3 {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin: 0.5rem 0;
}

.link-holder button.btn-orange {
  width: auto !important;
}

.shareable-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.shareable-links > button {
  width: 3.5rem;
  height: 3.5rem;
}

.shareable-links > button > span {
  font-size: 0.7rem;
}

.link-holder button.btn-orange svg {
  fill: rgb(255 255 255 / var(--tw-text-opacity));
}

.tab-based-componenet {
  margin-top: -5.2rem;
}

.icon-btn {
  margin-right: 1rem;
  text-align: center;
  border: 1px solid #e5e7eb;
  aspect-ratio: 1/1;
  border-radius: 50%;
  width: 40px;
}

.icon-btn:hover {
  border-color: #7f2cea;
}

.icon-btn svg {
  fill: #000;
  margin: auto;
}

.icon-btn:hover svg {
  fill: #7f2cea;
}

button.secondary-btn:hover {
  border: 1px solid #9c6bfe;
  color: #9c6bfe;
}

.primary-btn {
  width: 100%;
  font-weight: bold;
  border: 1px solid #9c6bfe;
  background-color: #7f2cea;
  color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
}

.secondary-btn {
  width: 100%;
  font-weight: bold;
  border: 1px solid #e5e7eb;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
}

.primary-btn.w-auto,
.secondary-btn.w-auto {
  width: unset;
}

.primary-btn.rounded,
.secondary-btn.rounded {
  border-radius: 20px;
}

.secondary-btn:hover {
  border-color: #f87171;
  color: #f87171;
}

td .copy-btn {
  visibility: hidden;
  transform: scale(0.8);
}

tr:hover .copy-btn {
  visibility: visible;
}

td:has(.copy-btn) {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

td button > svg {
  fill: #c4c4c4;
}

td button:hover > svg {
  fill: #7f2cea;
}

.widgets {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
}

.rounded-pill-widget {
  flex: 1;
  overflow: hidden;
}

.rounded-pill-widget .detail {
  margin-left: 0.5rem;
}

.rounded-pill-widget .form-label {
  color: #a3a3a3;
}

.widget-value {
  font-family: GothamMedium;
  font-size: 1rem;
}

@media screen and (max-width: 1024px) {
  .tab-based-componenet {
    margin-top: auto;
  }
}

.verify-email {
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.msg {
  text-align: center;
  margin: auto 0px;
}

.account-methods > li input {
  margin-right: 0.5rem;
}

.image-input {
  position: relative;
  cursor: pointer;
  text-align: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 10rem;
  overflow: hidden;
}

.image-input.profile {
  width: 120px;
  height: 120px;
}

.img-content {
  position: absolute;
  top: 35%;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #eae9e9;
}

.image-input:hover .img-overlay {
  opacity: 0.5;
}

.img-overlay {
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.35;
  border-radius: 0.25rem;
}

.sideBarLogo img {
  height: 50px;
  text-align: center;
  margin-top: 1rem;
}
.login-page__bg {
  object-fit: contain;
}
.sideBarLogo {
  display: flex;
  justify-content: center;
}
.logoImg {
  margin-bottom: 2rem;
}
.logoImg img {
  height: 60px;
  margin: 0 auto;
}
.w-full {
  position: relative;
}

.td-platform_url,
.td-access_key {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.permission-chip {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  color: white;
  fill: white;
  background-color: #8564ea;
}

.permission-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.permission-chip svg {
  margin-left: 0.25rem;
  transform: scale(0.7);
}

.chip-mode.positive {
  font-weight: unset;
  background-color: whitesmoke;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.chip-mode.negative {
  font-weight: unset;
  background-color: whitesmoke;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.nav-link-diasbled {
  cursor: not-allowed;
  opacity: 0.5;
}
.nav-link-diasbled a {
  pointer-events: none;
}

.filter-bar__filter {
  position: absolute;
  top: 3rem;
  padding: 1rem;
  min-height: 15rem;
  min-width: 20rem;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  z-index: 1;
  right: 0;
}

.searchBar {
  position: relative;
}

.filter-bar__filter .actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.date-range {
  display: flex;
  gap: 1rem;
}

.invoiceModal h3 {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin: 0.5rem 0;
}
.modalIcon svg {
  height: 100px;
  width: 100px;
  margin: -3rem auto 0;
}

.boarding-card .modalIcon svg {
  height: 100px;
  width: 100px;
  margin: auto;
}

.modalInline {
  margin: 4rem 0;
}
.modalInline h4 span {
  float: right;
}
.modalInline h4 {
  margin: 1rem 0;
}

.rounded-btn {
  border: 1px solid #ededed;
  border-radius: 50px;
  padding: 0.8rem;
  aspect-ratio: 1/1;
}

.filter-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.barOptions {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.signup-content h3,
.card h3 {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  @apply text-blackolive dark:text-brightgray font-bold font-gotham-bold;
}

@screen maxSm {
  .signup-card {
    padding: 1.5rem 1rem;
  }

  .signup-card h2 {
    @apply mb-3.5 text-2xl;
  }

  .signup-card p,
  .signup-card a {
    @apply text-sm;
  }
}

@screen minMaxSm {
  .signup-card {
    padding: 2.813rem 1.875rem;
  }

  .signup-card h2 {
    font-size: 1.7rem;
    @apply mb-4;
  }
}

@screen minMaxMd {
  .signup-card {
    padding: 3.125rem 2.188rem;
  }

  .signup-card h2 {
    font-size: 2rem;
    @apply mb-5;
  }
}

@screen minMaxLg {
  .signup-card {
    padding: 4.375rem 3.438rem;
  }
}

/* addwallet */
.wallet-card {
  @apply shadow-default rounded-xl bg-seashell flex justify-center flex-wrap;
  max-width: 70.188rem;
}

.wallet-left {
  width: 55%;
  @apply p-14 bg-white rounded-tl-xl rounded-bl-xl;
}

.dark .wallet-left {
  @apply bg-vampireblack;
}

.wallet-card h3 {
  @apply text-2xl font-bold mb-2 font-gotham-bold;
}

.form-select {
  @apply bg-transparent text-black border border-lavenderblue rounded-md w-full outline-none md:px-2.5 md:py-2 py-2.5 px-2 appearance-none relative cursor-pointer;
}

.form-select-arrow {
  @apply inline-block relative cursor-pointer;
}

.form-select-arrow:before {
  content: "";
  right: 1.375rem;
  background: url("../images/downarrow.svg") no-repeat right;
  width: 1.875rem;
  height: 1.875rem;
  @apply absolute z-0 top-1.5;
}

.wallet-right {
  width: 45%;
  @apply p-14 h-full;
}

.dark .wallet-card {
  @apply bg-lighteerieblack;
}

.wallet-right ol {
  list-style: number;
  padding-left: 1.125rem;
  @apply text-sm list-outside leading-6;
}

.wallet-right ol ul {
  list-style-type: circle;
  @apply ml-8;
}

@screen maxMd {
  .connect-wallets .card {
    @apply mt-10;
  }
}

@media screen and (min-width: 421px) and (max-width: 767px) {
  .connect-wallets .card {
    margin-right: 7%;
    margin-left: 7%;
  }
}

@media screen and (min-width: 320px) and (max-width: 420px) {
  .connect-wallets .card {
    @apply mt-6;
    margin-right: 4%;
    margin-left: 4%;
  }

  .rounded-pill-widget {
    width: 100%;
  }
}

.connect-wallets .card p:not(li p) {
  @apply text-center font-gotham-regular text-darkliver text-base;
  margin-top: 1.375rem;
}

.dark .connect-wallets .card p:not(li p) {
  @apply text-taupegray;
}

@screen maxSm {
  .connect-wallets .card p:not(li p) {
    @apply text-sm;
  }
}

.connect-wallets ul {
  @apply w-full;
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}

@screen maxSm {
  .connect-wallets ul {
    @apply my-8;
  }
}

.connect-wallets ul li {
  @apply shadow-element w-full rounded-md;
  padding: 1.813rem;
}

.dark .connect-wallets ul li {
  @apply bg-darkgunmetal;
}

@screen maxSm {
  .connect-wallets ul li {
    @apply p-5;
  }
}

.connect-wallets ul li:not(:last-child) {
  margin-bottom: 2.188rem;
}

@screen maxSm {
  .connect-wallets ul li:not(:last-child) {
    @apply mb-4;
  }
}

.connect-wallets ul li h5 {
  @apply text-safetyorange font-gotham-bold text-xl;
}

@screen maxSm {
  .connect-wallets ul li h5 {
    @apply text-base;
  }
}

.connect-wallets ul li p {
  @apply text-lightquicksilver mt-3.5;
}

@screen maxSm {
  .connect-wallets ul li p {
    @apply text-sm mt-2;
  }
}

.connect-wallets__bottom {
  @apply mt-6 text-base;
}

@screen maxSm {
  .connect-wallets__bottom {
    @apply text-sm text-center;
  }
}

.connect-wallets {
  @apply flex items-center justify-center flex-col;
}

.connect-wallets .card {
  @apply mb-5;
  max-width: 42.188rem !important;
}

.wallet-right ol li {
  @apply mb-2;
}

@screen maxMd {
  .wallet-left,
  .wallet-right {
    @apply w-full;
  }

  .wallet-left {
    @apply rounded-xl;
  }

  .wallet-right {
    @apply h-auto rounded-b-xl;
  }
}

@screen maxSm {
  .wallet-card h3 {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

@screen minMaxMd {
  .signup-content {
    @apply h-full;
  }

  .wallet-right {
    @apply px-6;
  }
}

/*  wallet added */

.wallet-added {
  @apply flex items-center justify-center flex-col;
}

@screen maxMd {
  .wallet-added {
    @apply my-10;
    margin-left: 4%;
    margin-right: 4%;
  }

  .signup-content {
    @apply justify-start mt-10;
    height: calc(100vh - 2.5rem);
  }
}

.signup {
  @apply min-h-screen;
}

@media screen and (max-height: 575px) {
  .signup,
  .signup-content {
    @apply h-auto !important;
  }

  .login .brand-logo {
    @apply mt-6;
  }

  .signup-card {
    @apply mb-6;
  }

  .login-page__img {
    @apply h-full !important;
  }
}

.wallet-added .card {
  padding: 2.625rem;
}

.wallet-added .card button {
  @apply w-auto !important;
}

.wallet-added_image {
  @apply absolute;
  top: 5.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.wallet-added_image img {
  max-height: 12.5rem;
}

@media screen and (max-width: 520px) {
  .wallet-added_image {
    width: 11.25rem;
  }

  .wallet-added p {
    margin-top: 11.25rem !important;
  }
}

.wallet-added p {
  @apply text-center text-darkliver font-gotham-regular;
  margin-bottom: 2.375rem;
  margin-top: 228px;
}

.dark .wallet-added p {
  @apply text-taupegray;
}

.login-page__img {
  @apply relative h-screen flex;
  background: #7f2cea;
}

@screen maxMd {
  .login-page__img {
    @apply hidden;
  }
}

@screen maxMd {
  .wallet-left p {
    font-size: 0.875rem;
  }

  .wallet-left,
  .wallet-right {
    @apply p-5;
  }
}

.wallet-right strong {
  @apply font-gotham-bold;
}

@screen minMaxLg {
  .login .signup-card {
    max-width: 29.25rem;
  }
}

@screen minMaxMd {
  .login .signup-card {
    max-width: 22rem;
  }
}

/* wallet */
.wallet .wallet-card {
  @apply max-w-full;
}

@screen minMaxLg {
  .wallet .wallet-left {
    @apply p-7;
  }
}

/* wallet list */
.wallet-list {
  @apply flex justify-center flex-col;
  margin-top: 2.625rem;
  @apply w-full;
}

.wallet-list li {
  padding: 1rem 3.75rem;
  @apply border border-lightbrightgray dark:border-charlestongreen rounded-t-xl cursor-pointer relative dark:bg-darkgunmetal;
}

.wallet-list li.open {
  @apply rounded-b-none;
}

@screen minMaxMd {
  .wallet-list li {
    @apply px-12;
  }
}

.wallet-list > li {
  margin-bottom: 1.125rem;
}

@screen maxMd {
  .wallet-list {
    @apply mt-5;
  }

  .wallet-list li {
    padding: 0.785rem 2rem;
    @apply flex-wrap;
  }
}

.wallet-list li:not(.accordion-item li):before {
  content: "";
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  height: 2.938rem;
  @apply absolute top-1/2 left-0 w-1.5 bg-catalinablue;
  transform: translate(-3%, -50%);
}

.wallet-list .accordion-container:nth-child(3n) .accordion-title::before {
  @apply bg-seagreen;
}

.wallet-list .accordion-container:nth-child(3n + 2) .accordion-title::before {
  @apply bg-safetyorange;
}

.accordion li h2 {
  @apply font-gotham-bold flex items-center;
}

@screen maxLg {
  .accordion li h2 {
    @apply mt-1;
  }
}

.accordion li h2 img,
.accordion-container .dots__icon img {
  @apply ml-4;
  transform: rotate(-90deg);
}
.accordion-container .dots__icon:hover {
  background: rgb(220 220 220 / 45%);
  transition: all 0.3s;
}
.dark .accordion-container .dots__icon:hover {
  background: rgb(220 220 220 / 20%);
}
.dark .accordion .accordion-title h2 img {
  @apply invert;
}
.accordion-container .dots__icon {
  @apply rounded-full;
}
.dark .accordion-container .dots__icon img {
  @apply mx-auto invert-0;
}
.accordion-container .dots__icon img {
  @apply w-1 invert;
}

.accordion li .dots__icon,
.accordion-container .dots__icon {
  right: 30px;
  top: 30px;
  width: 35px;
  height: 35px;
}

.dark .accordion li .dots__icon {
  @apply invert brightness-100;
}

@screen maxMd {
  .accordion li .dots__icon,
  .accordion-container .dots__icon {
    @apply right-0;
    top: 30px;
  }
}

@screen minMaxMd {
  .accordion li .dots__icon,
  .accordion-container .dots__icon {
    top: 35px;
    right: 16px;
  }
}

.accordion li .dots__icon img {
  filter: invert(1);
  @apply w-1;
}

@screen maxMd {
  .accordion li .dots__icon img,
  .accordion-container li .dots__icon img {
    transform: revert;
    width: 0.2rem;
  }
}

.wallet-list li p {
  @apply text-outerspace2 mt-1 dark:text-lightquicksilver;
}

@screen maxLg {
  .wallet-list li p {
    font-size: 0.9rem;
    line-height: 1rem;
  }
}

.wallet-list-card {
  @apply bg-white dark:bg-black1;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  padding: 2.688rem 3.75rem 4.75rem;
  border-radius: 1.25rem;
}

@screen minMaxLg {
  .wallet-list-card {
    padding: 1.5rem 2.5rem 2.5rem;
  }
}

@screen maxLg {
  .wallet-list-card {
    padding: 1rem 1.5rem 1.5rem;
  }
}

/* accordion */
.accordion {
  margin-bottom: 1.125rem;
}

.accordion-disabled {
  @apply opacity-50;
}

.accordion-list .accordion-item li::before {
  @apply hidden;
}

.accordion-list .accordion-item li:last-child {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.accordion-list {
  @apply rounded-lg dark:bg-eerieblack;
}

/* .accordion-list .tag-design {
  @apply bg-lumber;
} */
/* .dark .accordion-list .tag-design {
  background: rgba(147, 88, 45, 0.26);
} */

/* .accordion-list .accordion-item li:hover {
  box-shadow: 6px 0px 16px rgba(0, 0, 0, 0.08), 0px 6px 16px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s;
}
.dark .accordion-list .accordion-item li:hover {
  @apply bg-eerieblack;
  box-shadow: 0px -6px 16px rgba(195, 195, 195, 0.09),
    0px 6px 16px rgba(196, 196, 196, 0.09);
} */
.accordion-list .accordion-item li.open {
  @apply rounded-bl-none rounded-br-none;
}

.accordion-list li:not(.open) {
  @apply rounded-none border-t-0;
}

.accordion-item li {
  border-radius: 0;
}

.accordion-item {
  max-height: 420px;
  overflow-y: auto;
  transition: max-height 0.3s;
}

.accordion-item.collapsed {
  @apply max-h-0 p-0 border-0 overflow-hidden;
  transition: max-height 0.35s;
}

.accordion h3 {
  @apply font-gotham-bold text-lg mb-0 dark:text-brightgray;
}

.accordion-title {
  @apply w-full flex justify-between items-center;
}

.accordion-title h3 {
  @apply mb-0;
}

.accordion-item__list {
  @apply flex items-center;
}

.accordion-item__list h3,
.accordion-item__list h2,
.accordion-item__list {
  @apply text-sm;
}

.accordion-item__list h2,
.accordion-item__list p {
  /* width:73px; */
  word-break: break-word;
}
.accordion-item__list h2 {
  @apply justify-end;
}
@screen maxSm {
  .accordion-item__list {
    @apply flex-wrap;
  }
}

.accordion-item__list img {
  width: 2.188rem;
  height: 2.188rem;
  @apply mr-5;
}

@screen maxLg {
  .accordion-item__list img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

/* Select css */
.select-wallet .css-26l3qy-menu {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.17);
  border-radius: 0.75rem;
  padding: 1.25rem 2rem;
}

.select-wallet .css-4ljt47-MenuList {
  max-height: 19.375rem;
}

@screen minMaxMd {
  .select-wallet .css-26l3qy-menu {
    padding: 1.25rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 400px) {
  .select-wallet .css-26l3qy-menu {
    padding: 0.625rem;
  }
  .sideBarLogo {
    display: none;
  }
}

.select-wallet .css-yt9ioa-option,
.select-wallet .css-1n7v3ny-option,
.select-wallet .css-9gakcf-option {
  @apply cursor-pointer my-2.5 font-gotham-medium border text-gunmetal;
  @apply bg-transparent !important;
  width: 95%;
  border-radius: 0.375rem;
  border: 1px solid transparent;
}

.select-wallet .css-yt9ioa-option:hover,
.select-wallet .css-1n7v3ny-option:hover,
.select-wallet .css-9gakcf-option {
  @apply border border-lavenderblue;
}

.select-wallet .css-1s2u09g-control {
  height: 2.625rem;
  @apply rounded-md border border-columbiablue w-full text-black;
}

.select-wallet .css-6j8wv5-Input {
  @apply text-taupegray;
}

.select-wallet .css-4ljt47-MenuList::-webkit-scrollbar {
  width: 0.25rem;
}

.select-wallet .css-4ljt47-MenuList::-webkit-scrollbar-track {
  @apply bg-lightbrightgray;
}

.dark .select-wallet .css-4ljt47-MenuList::-webkit-scrollbar-track {
  @apply bg-onvx;
}

.select-wallet .css-4ljt47-MenuList::-webkit-scrollbar-thumb {
  @apply bg-lightgainsboro;
}

.dark .select-wallet .css-4ljt47-MenuList::-webkit-scrollbar-thumb {
  @apply bg-davysgray;
}

.dark .select-wallet .css-1s2u09g-control,
.dark .select-wallet .css-1pahdxg-control {
  @apply bg-darkgunmetal border-lavenderblue;
}

.dark .select-wallet .css-26l3qy-menu {
  @apply bg-darkgunmetal;
}

.dark .select-wallet .css-4ljt47-MenuList p,
.dark .select-wallet .css-qc6sy-singleValue p {
  @apply text-brightgray;
}

.dark .select-wallet .css-yt9ioa-option p,
.dark .select-wallet .css-1n7v3ny-option {
  @apply text-brightgray;
}

/* form error css for login form */

.chart-wrapper {
  @apply flex justify-center items-center;
  max-width: 100% !important;
  height: 274px !important;
}

/* your-portfolio */
.dashboard-card {
  @apply bg-white dark:bg-richblack shadow-shadowCustom dark:shadow-darkShadowCustom px-14 py-16;
  border-radius: 0.5rem;
}

.dashboard-details {
  @apply flex flex-wrap;
}

.portfolio-content {
  @apply flex flex-wrap justify-between;
}

.upper-bend,
.lower-bend {
  @apply relative font-bold pl-5;
}

.upper-bend {
  @apply text-seagreen;
}

.upper-bend:after,
.lower-bend:after {
  content: "";
  @apply absolute;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  top: 0.3rem;
  left: 0.5rem;
}
.upper-bend:after {
  border-bottom: 8px solid #47ba8a;
}

.lower-bend {
  @apply text-cinnabar;
}

.lower-bend:after {
  border-top: 8px solid red;
}

.portfolio-right ul li {
  display: inline-block;
}

.portfolio-right ul li button {
  border-radius: 10px;
  @apply uppercase py-2.5 px-3 text-taupegray text-sm my-1 mx-0.5 font-bold;
  transition: all 0.3s ease;
}

.portfolio-right ul li button:hover,
.portfolio-right ul li button.active {
  @apply bg-white text-black dark:bg-eerieblack dark:text-white;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.15);
}

.market-value ul li {
  @apply text-cadetgrey dark:text-lightquicksilver text-sm capitalize block relative;
}

.market-value ul {
  @apply mt-8 ml-7;
}

.market-value ul li:before {
  content: "";
  @apply absolute bg-white rounded-full border-persianblue border;
  left: -25px;
  width: 18px;
  height: 18px;
}

.market-value ul li:after {
  content: "";
  top: 5px;
  @apply border-4 border-persianblue absolute rounded-full -left-5;
}

.market-value ul li:last-child::before,
.market-value ul li:last-child::after {
  @apply border-crayolayellow;
}

@media (min-width: 768px) and (max-width: 902px) {
  .portfolio-right > ul {
    margin-top: 16px;
  }
}

.your-assets {
  @apply my-9;
}

.card-heading {
  @apply mb-6;
}

/* @screen md {
  .assets-heading {
    @apply px-6;
  }
}
@screen lg {
  .assets-heading {
    @apply px-14;
  }
} */

/* .assets-heading a {
  transition: 0.3s;
  @apply font-bold text-catalinablue dark:text-lightquicksilver relative inline-flex items-center font-gotham-medium;
} */
/* .assets-heading a svg {
  @apply ml-2;
} */
.asstes-table_image img {
  width: 2.188rem;
  height: 2.188rem;
}

/* .assets-heading a:hover {
  @apply text-crayolasabsolutezero;
} */
.market-value {
  margin-top: 16px;
}

.market-value ul {
  margin-left: 10%;
}

@screen maxMd {
  .portfolio-right ul {
    margin-top: 14px;
  }

  .dashboard-card {
    padding: 16px;
    border-radius: 12px;
  }

  .market-value {
    @apply mt-6;
  }

  .market-value ul {
    margin-left: 26px;
  }

  .portfolio-right ul li button {
    padding: 8px;
    font-size: 12px;
  }
}

@screen minMaxMd {
  .dashboard-card {
    @apply px-7 py-9 rounded-xl;
  }
}

@screen minMaxLg {
  .dashboard-card {
    @apply px-8 py-12;
  }

  .portfolio-right ul li button {
    padding: 8px;
    font-size: 14px;
  }

  .market-value ul {
    margin-left: 14%;
  }
}

.title-lg {
  @apply xl:text-4xl lg:text-3xl text-2xl font-bold mt-4;
}

.dark .boarding-card,
.dark .notification-settings,
.dark .account-settings {
  @apply bg-black1;
}

.notification-settings,
.account-settings {
  @apply bg-white shadow-shadowCustom dark:shadow-darkShadowCustom;
  border-radius: 0.5rem;
  padding: 2.688rem 3.75rem;
}
.shadow {
  @apply shadow-shadowCustom dark:shadow-darkShadowCustom;
}

@screen maxSm {
  .notification-settings,
  .account-settings {
    padding: 1.5rem;
  }
}

.notification-settings__list p {
  @apply text-base;
}

@screen maxSm {
  .notification-settings__list,
  .account-settings__form {
    @apply mt-7 !important;
  }
}

.notification-settings__list li {
  @apply flex items-center;
}

@screen maxSm {
  .notification-settings__list li {
    @apply text-base;
  }

  .notification-settings__list li div span {
    @apply mr-1.5;
  }
}

.notification-settings__list li:not(:last-child) {
  @apply mb-4;
}

.notification-settings__list li div {
  @apply ml-auto flex;
}

@screen sm {
  .notification-settings__list li div {
    width: 6.813rem;
  }
}

.notification-settings__list li label {
  @apply ml-auto;
}

@screen maxSm {
  .notification-settings__list li label .slider::before {
    bottom: 0.21rem !important;
  }
}

.account {
  @apply mt-9 mb-8;
}

.account-settings__form .form-group-row label {
  @apply font-gotham-regular text-base;
}

.form-group-row {
  @apply flex items-center;
}

.form-group-row:not(:last-child) {
  @apply mb-4;
}

.form-group-row label {
  width: 30%;
}

.form-group-row:last-child label {
  width: 65%;
}

@media screen and (min-width: 1400px) {
  .notification-settings__list,
  .account-settings__form {
    margin-right: 16%;
  }
}

@media screen and (min-width: 1200px) {
  .notification-settings__list,
  .account-settings__form,
  .delete-alert {
    margin-left: 3.75rem;
  }
}

.account-settings__form button[type="button"] {
  /* @apply w-20 !important; */
  width: 10rem !important;
}

@media screen and (max-width: 420px) {
  .form-group-row {
    @apply flex-wrap;
  }

  .form-group-row p {
    @apply p-0;
    flex-basis: 71%;
  }

  .notification-settings__list li div span {
    @apply text-sm;
  }

  .account-settings__form button[type="button"] {
    width: 4.3rem !important;
  }
}

@screen sm {
  .form-group-row label {
    @apply text-lg;
    width: 10.75rem;
    margin-right: 4.625rem;
  }
}

.form-group-row p {
  @apply w-auto text-quicksilver;
}

.form-group-row label,
.form-group-row p {
  @apply font-gotham-medium;
}

.form-group-row:nth-child(2) p {
  @apply text-eerieblack;
}

.dark .form-group-row:nth-child(2) p {
  @apply text-lightquicksilver;
}

@screen sm {
  .account-settings button img {
    @apply mr-1;
  }
}

.form-group-row button {
  @apply ml-auto;
}

.form-group-row p.password {
  @apply text-quicksilver;
}

.delete-alert {
  @apply text-lightquicksilver font-gotham-regular mt-5;
  max-width: 531px;
  font-size: 0.75rem;
}

.box {
  @apply w-9 h-9 mr-4 rounded-sm bg-honeydew dark:bg-darkgunmetal flex items-center justify-center;
}

.box-2 {
  @apply bg-lightquicksilver dark:bg-darkcharcoal w-9 h-9 rounded-full flex items-center justify-center;
}

.accordion-modal {
  @apply items-start;
}

.accordion-modal-list {
  @apply md:grid md:grid-cols-3 mt-4 border-b border-dashed border-lightbrightgray dark:border-onyx pb-4 relative items-center w-full;
}

@screen lg {
  .accordion-modal-list {
    width: calc(100% - 10rem);
    @apply mx-20;
  }
}

.accordion-modal h4 {
  @apply text-base font-gotham-medium;
}

.accordion-modal-list p {
  @apply text-lightquicksilver mt-1 text-sm;
}

.col-center {
  @apply flex items-center justify-end;
}

@screen maxMd {
  .col-center {
    @apply my-6 justify-between;
  }
}

@screen maxLg {
  .col-center svg {
    margin-left: 0.875rem;
    @apply mr-3;
  }
}

@screen minMaxLg {
  .col-center svg {
    margin-left: 1.313rem;
    margin-right: 1.938rem;
  }
}

@screen lg {
  .col-center svg {
    margin-left: 2.313rem;
    margin-right: 2.938rem;
  }
}

.col-center p {
  @apply font-gotham-medium text-gunmetal dark:text-lightquicksilver mt-0;
}

.dots__icon {
  @apply absolute right-0;
}

@screen maxMd {
  .dots__icon {
    bottom: 30px;
    right: 14px;
  }
}

.arrow-down {
  @apply rotate-180 bg-floralwhite;
}

/* assets table css */

.accordion-modal .modal-header h3 {
  @apply mb-0;
}

.modal-md {
  width: 35%;
}

@screen maxMd {
  .modal-md {
    width: 90%;
  }
}

.change-password .form-group:nth-child(3) {
  @apply mb-0;
}

.accordion-dropdown li {
  @apply rounded-none font-gotham-regular border-0;
  padding: 8px 40px;
  font-size: 16px;
}

.accordion-dropdown li:first-child {
  @apply mb-3;
}

.accordion-dropdown li:last-child {
  @apply border-t border-dashed dark:border-black dark:text-white;
}

.accordion-dropdown li::before {
  @apply hidden;
}

.dark .accordion-dropdown li:hover {
  @apply bg-black;
}

.accordion-dropdown ul {
  @apply py-4 rounded-lg dark:bg-darkgunmetal;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
}

.accordion-dropdown .szh-menu__arrow.szh-menu__arrow--dir-bottom {
  @apply dark:bg-darkgunmetal;
}

.accordion-dropdown ul {
  right: 25px;
  left: inherit !important;
  /* top: -60px !important; */
}

/* @screen minMaxMd {
  .accordion-dropdown ul {
    top: -50px !important;
  }
}
@screen maxMd {
  .accordion-dropdown ul {
    top: -35px !important;
  }
}
@media screen and (max-width: 405px) {
  .accordion-dropdown ul {
    top: -50px !important;
  }
} */
.accordion-container .szh-menu-container {
  @apply w-full !important;
}

.accordion-dropdown ul li img {
  @apply transform-none ml-0 mr-4 dark:invert-0;
  filter: invert(1);
  width: 0.9rem;
}

.switch {
  z-index: 10;
}

.accordion-dropdown .switch-default .slider,
.notification-settings__list .switch-default .slider {
  height: 1.5rem;
  width: 3.2rem;
}

.accordion-dropdown .switch-default .slider:before {
  height: 0.8rem !important;
  width: 0.8rem !important;
  bottom: 0.4rem !important;
  left: 0.3rem !important;
}

.accordion-dropdown .switch-default input:checked + .slider::after {
  content: "";
  background: url("../images/done-arrow.svg") no-repeat;
  height: 0.8rem;
  width: 0.8rem;
  left: 0.6rem;
  bottom: 0.2rem;
  @apply absolute;
  filter: invert(0);
}

.accordion-dropdown .switch-on-off .slider::after {
  content: "";
  background: url("../images/cross.svg") no-repeat;
  height: 0.8rem;
  width: 0.6rem;
  right: 0.5rem;
  bottom: 0.2rem;
  @apply absolute bg-contain;
  filter: invert(1);
}

.switch-on-off.switch-default .slider {
  top: 4px;
}

.switch-on-off__label {
  min-width: 80px;
  @apply break-words;
}

@screen maxSm {
  .switch-on-off {
    @apply mr-2;
  }
}

.signup-success p {
  margin-top: 200px;
}

.signup-success .wallet-added_image {
  top: 44%;
}

@screen maxLg {
  .signup-success .wallet-added_image {
    top: 40%;
  }
}

@media screen and (max-height: 515px) {
  .signup-success .logo {
    margin-top: 10rem;
  }

  .signup-success .wallet-added_image {
    top: 55%;
  }
}

@media screen and (max-width: 520px) {
  .signup-success .wallet-added_image {
    top: 42%;
    width: 11.25rem;
  }

  .signup-success p {
    margin-top: 140px !important;
  }
}

.network-chart-wrapper canvas {
  height: 5rem !important;
}

/* dashboard_card */

.dashboard-title {
  font-size: 1.188rem;
  @apply text-yankeesblue font-semibold font-gotham-regular;
}

.dashboard-subtitle,
.dashboard-value {
  @apply text-sm mt-1;
}
.dashboard-value {
  @apply ml-1;
}
.asset-card-content img {
  @apply object-contain w-10 h-10;
}

.asset-card {
  @apply flex justify-between items-center pb-4 border-b border-brightgray2 mb-3 dark:border-brightgray;
}

.asset-card-content {
  @apply flex items-center;
}

.asset-bottom > div {
  @apply text-center;
}

.asset-bottom-left:before {
  content: "";
  top: 0.313rem;
  width: 0.063rem;
  @apply right-0 h-10 bg-brightgray2 absolute;
}

.asset-bottom {
  @apply flex justify-center;
}

.asset-bottom-left h4 {
  @apply font-semibold;
}
.asset-bottom h5 {
  @apply text-sm text-cadetblue dark:text-brightgray;
}
.asset-card-content h5 {
  @apply font-semibold;
  min-width: 88px;
  word-break: break-word;
}
.assets_content {
  @apply grid xl:grid-cols-3 md:grid-cols-2 md:gap-4 gap-1;
}
.dashboard_content {
  @apply grid lg:grid-cols-3 md:grid-cols-2 md:gap-4 gap-1;
}
.dashboard_content .dashboard-card {
  @apply mb-4 p-5 flex justify-between;
}

@media (min-width: 1600px) and (max-width: 1920px) {
  .dashboard_content .dashboard-card {
    @apply justify-around;
  }
}

.asset-card h4 {
  @apply font-semibold text-outerspace dark:text-brightgray ml-1.5;
}
@media (min-width: 1024px) and (max-width: 1300px) {
  .dashboard-card_img {
    @apply w-10 h-10;
  }
  .asset-bottom-left {
    @apply pr-1;
  }
  .asset-bottom div:last-child {
    @apply pl-1;
  }
}
@media (min-width: 1326px) and (max-width: 1440px) {
  .asset-bottom-left {
    @apply pr-1;
  }
  .asset-bottom div:last-child {
    @apply pr-1;
  }
}

@media (min-width: 1326px) and (max-width: 1450px) {
  .assets_content .dashboard-card {
    @apply p-4;
  }
  /* .asset-bottom-left h4 {
    @apply text-sm;
} */
  .asset-card-content img {
    @apply w-9 h-9;
  }
  .asset-card-content h5 {
    @apply text-sm;
  }
  .asset-card h4 {
    @apply text-sm;
  }
}

.form-control-addon .form-control.select {
  padding-right: 0px;
}

*:has(*:is(input, textarea):required) > label::after,
*:has(.required) > label::after {
  content: " *";
  color: red;
}

a.orange-link svg {
  display: unset;
  opacity: 0;
}

label:hover .orange-link svg {
  opacity: 1;
}

.szh-menu {
  padding: 0px !important;
}

.notification-wrapper {
  position: relative;
  height: 25rem;
  overflow-y: auto;
}

.notification-wrapper::-webkit-scrollbar {
  width: 0.5rem;
}

.read-all-holder {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 1rem 0rem 0.5rem 0rem;
}
